.App {
  text-align: center;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input, select {
  padding: 0.5rem;
  font-size: 1rem;
  width: -webkit-fill-available;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /*background-color: #61dafb;*/
  border: none;
  cursor: pointer;
}
